<template>
  <v-card
    :outlined="!$vuetify.theme.dark"
    elevation="0"
    class="hs-rounded-xl"
    :disabled="disabled"
    :width="maxWidth"
    @click="$emit('open')"
  >
    <v-container style="display: flex;">
      <v-img
        :src="getResourceUrl(thumbnail)"
        height="260px"
        width="260px"
        class="hs-rounded-xl"
      ></v-img>
    </v-container>

    <v-card-title
      class="text-truncate"
      style="display: block"
    >
      {{ name }}
    </v-card-title>

    <v-card-actions>
      <v-badge
        dot
        left
        inline
        :color="memberCount > 0 ? 'tertiary' : 'grey'"
        class="ml-1"
      >
        <span
          class="caption adjustY"
          v-text="$t('onlineStatus.members', { amount: memberCount })"
        ></span>
      </v-badge>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        :class="iconClass"
      >
        <v-icon :color="iconColor" small>{{ buzz ? mdiFire : mdiStarFourPointsOutline }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mdiFire, mdiStarFourPointsOutline } from '@mdi/js'
  import { getResourceUrl } from '@utils'

  export default {
    props: {
      name: {
        type: String,
        default: ''
      },
      thumbnail: {
        type: String,
        default: ''
      },
      memberCount: {
        type: Number,
        default: 0
      },
      buzz: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      window.addEventListener('resize', this.getWidth)
    },

    data: () => ({
      mdiFire,
      mdiStarFourPointsOutline,
      width: window.innerWidth,
    }),

    computed: {
      iconColor() {
        return this.buzz ? 'orange darken-3' : ''
      },
      iconClass() {
        return this.buzz ? 'buzzing' : ''
      },
      maxWidth() {
        return this.$vuetify.breakpoint.xl ? '18%' : '23%'
      },
      cardTextHeight() {
        return this.width > 1400 ? 13 : 10
      }
    },
    
    methods: {
      getResourceUrl,
      getWidth() {
        this.width = window.innerWidth
      }
    }
  }
</script>

<style scoped>
.buzzing {
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#eeb90a25;
}
</style>