<template>
  <div class="px-12">
    <PageLayout
      :tab="tab"
      :setTab="setTab"
    >
      <template v-slot:child="pageProps">
        <div>
          <v-container
              v-if="tab === 0"
              class="py-0 pr-0"
            >
              <ScrollPagination
                :requestData="{
                  'url': `rooms/${pageProps.user.id}`,
                  'params': {
                    community: $route.query.id
                  }
                }"
                :countData="{
                  'url': `rooms/${pageProps.user.id}/count`,
                  'params': {
                    community: $route.query.id
                  }
                }"
                :rootClass="'row px-2'"
                :setFatherItems="setRooms"
              >
                <template v-slot:child="scrollProps">
                  <ContentRoom
                    :key="`room_${scrollProps.index}`"
                    :name="scrollProps.item.name"
                    :thumbnail="scrollProps.item.thumbnail"
                    class="mb-4"
                    style="margin-right: 1.2vw"
                    @open="$router.push(`community/room?id=${scrollProps.item.id}`)"
                  />
                </template>
              </ScrollPagination>
            </v-container>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentRoom from '@components/Room/ContentRoom'
import ScrollPagination from '@components/App/ScrollPagination'
import PageLayout from '@components/Hall/PageLayout'

export default {
  components: {
    ContentRoom,
    ScrollPagination,
    PageLayout
  },

  data() {
    return {
      tab: 0,
      rooms: [],
      members: [],
      community: {},
      member: {},
    }
  },

  computed: {
    ...mapGetters({
      anna: 'anna',
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),
  },

  methods: {
    setTab(tab) {
      this.tab = tab
    },

    setRooms(rooms) {
      this.rooms = rooms
    },
  }
}
</script>
