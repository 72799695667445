var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-12"},[_c('PageLayout',{attrs:{"tab":_vm.tab,"setTab":_vm.setTab},scopedSlots:_vm._u([{key:"child",fn:function(pageProps){return [_c('div',[(_vm.tab === 0)?_c('v-container',{staticClass:"py-0 pr-0"},[_c('ScrollPagination',{attrs:{"requestData":{
                'url': ("rooms/" + (pageProps.user.id)),
                'params': {
                  community: _vm.$route.query.id
                }
              },"countData":{
                'url': ("rooms/" + (pageProps.user.id) + "/count"),
                'params': {
                  community: _vm.$route.query.id
                }
              },"rootClass":'row px-2',"setFatherItems":_vm.setRooms},scopedSlots:_vm._u([{key:"child",fn:function(scrollProps){return [_c('ContentRoom',{key:("room_" + (scrollProps.index)),staticClass:"mb-4",staticStyle:{"margin-right":"1.2vw"},attrs:{"name":scrollProps.item.name,"thumbnail":scrollProps.item.thumbnail},on:{"open":function($event){return _vm.$router.push(("community/room?id=" + (scrollProps.item.id)))}}})]}}],null,true)})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }